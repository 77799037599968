<template>
  <div>
    <Header/> 
    <div class="details">
        <!-- <el-image   style="width:100%;height:100%;" :src="logo" :preview-src-list="[logo]"></el-image> -->

     <VuePlayerVideo :src="video"></VuePlayerVideo>
    </div>
    <div style="width:100%;height:auto">
      <div style="margin-top: 15px;"></div>
      <div class="lesson-tools">
        <div style="height: 26px; margin-left: auto;" @click="collect">
          <div style="display: flex;" v-if="!collects">
            <button class="button">加書簽</button>
            <img src="../../assets/training_schedule/detail/unmark_icon.png" style="margin-right: 16px;width: 16px;height: 26px;">
          </div>
          <div style="display: flex;" v-else>
            <button class="button">已收藏</button>
            <img src="../../assets/lesson/mark_icon.png" style="margin-right: 16px;width: 16px;height: 26px;">
          </div>
        </div>
        <div style="height: 34px;">
          <button class="clear-btns round-btn" style="padding: 7px 10px;" @click="accomplish" v-if="!accomplishs">完成 >></button>
          <p style="padding: 7px 10px;color:#fff;" @click="accomplish" v-else >已完成</p>
        </div>
      </div>
      <div class="lesson-title">疫情後行業的機遇？</div>
      <div class="lesson-content">
          <div class="_root">
            <div>
              <p>疫情之下行業商機
                <br />
                在疫情底下，很多人認為各行各業生意都大受影響。有些行業其實做得很出色，
                <br />
                <br />
                例如 、外賣、 物流、 網購…同樣保險理財業亦受影響。但這是一個大機會，在疫情底下，亦有很多人對自己的保險反思
                 <br />
                <br />
                「自己真的一份 簡單基本的保障也沒有，其實絕對是這行業的大機會」
                 <br />
                <br />
                第二點 受到疫情影響很多不同的行業都會發生裁員、 停薪、留職，
                 <br />
                <br />
                被裁員的時候 發現原來公司有團體醫療福利，什至家人亦有受利 ，但被裁員後，才發覺 「原來自己什麼保障也沒有」，因為這樣令一些身邊的人去深思熟慮，思考是否該為自己配置一 份屬於自己的醫療保障。
              </p>
            </div>
          </div>
        </div>
      <div style="height:40px;"></div>
    </div>
  </div>
</template>

<style>
img, svg, video {
  display: unset;
  vertical-align: unset;
}
._root{
  padding: 1px 0;
  overflow-x: auto;
  overflow-y: hidden;
  color:#fff;
}
.lesson-content{
  font-size: 18px;
  line-height: 25.2px;
  padding: 15px;
  padding-top: 0;
  font-family: Helvetica Neue;
  font-weight: 300;
  text-align: left /* height: 100%; */;
}
.vue3-player-video .rounded-3xl{
    border-radius: 0rem;
}
.input_video{
  width: 400px;
  height: 400px;
  margin: 0 auto;
}
.round-btn img{
  margin-right: 5px;
}
.grace-list-title {
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
}
.grace-list-title-desc{
  display: block;
  font-size: 10px;
  color: #999;
  line-height: 15px;
  margin-left: 9px;
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
}
.grace-list-title-text {
    display: block;
    font-size: 12px!important;
    /* color: #333; */
    line-height: 19px;
    padding: 0px;
    font-weight: normal;
    width:50%;
    height:auto;
    color:#fff;
}
.grace-list-body{
  padding: 12px 0;
  margin-left: 12px;
  width: 48px;
  -webkit-box-flex: 1;
  flex: auto;
}
.grace-border-b {
    border-bottom: 1px solid #e9e9e9;
}
.grace-list-items {
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
}
.lesson-title{
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 10px;
  font-family: Helvetica Neue;
  font-weight: 400;
  font-size: 24px;
  text-align: left;
  color:#fff;
}
.round-btn{
  color: #fff;
  line-height: 130%;
  border: 1px solid #fff;
  border-radius: 999999px;
  background-color: initial;
}
.clear-btns{
  background: transparent;
}
.button{
  font-weight: 300;
  font-size: 14px;
  color: rgb(255, 255, 255);
  margin-right: 6px;
  line-height: 0;
  padding: 0;
  background: transparent;
  border: none;
}
.lesson-tools{
  height: 40px;
  margin-bottom: 10px;
  margin-right: 17px;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
}
  .details{
    width: 100%;
    height: 30vh;
    border-top: 1px solid #fff;
    margin-top: -15px;
  }
</style>
<script>
import Header from "../Header";
import VuePlayerVideo from 'vue3-player-video'
export default {
  components:{Header,VuePlayerVideo},
  data(){
    return {
      img:'',
      logo:'',
      video:'',
      content:[
      ],
      collects:false,
      accomplishs:false
    }
  },
  created(){
  },
  methods:{
    copy(data){
      let url = data
      let oInput = document.createElement('input')
      oInput.value = url
      document.body.appendChild(oInput)
      oInput.select() // 选择对象
      document.execCommand("Copy") // 执行浏览器复制命令
      this.$message({
        message: '下載的連結已複製，請通過你的wechat, whatapp 或電郵傳到電腦中進行下載。',
        type: 'success'
      })
      oInput.remove()
    },
    updateTime(e){
        this.currentTime = e.target.currentTime
    },
    collect(){
      if(this.collects){
        this.$message.error({message: "課程已取消收藏", duration: 10000});
      }else{
        this.$message.success({message: "課程已收藏", duration: 10000});
      }
      this.collects = !this.collects;
    },
    accomplish(){
      let id = this.$route.query.id;
      if(!id || id< 0){
        id  = 1;
      }
      this.accomplishs = true;
      this.$router.push({
        path:'/lesson_done/'+id
      });
    }
  }
}
</script>

